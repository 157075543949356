$video-teaser-border-radius: border-radius(m) !default;
$video-teaser-media-height--mobile: 335px !default;
$video-teaser-media-height--desktop: 460px !default;
$video-teaser-media-width--desktop: 70% !default;
$video-teaser-caption-padding--mobile: spacing(m) !default;
$video-teaser-caption-padding--desktop: spacing(xl) !default;
$video-teaser-caption-width--desktop: 100% - $video-teaser-media-width--desktop !default;
$video-teaser-title-color: color(white) !default;
$video-teaser-title-font-size: font-size(s) !default;
$video-teaser-title-font-family: $font-base !default;
$video-teaser-title-margin-bottom: spacing(s) !default;
$video-teaser-bodytext-color: color(white) !default;
$video-teaser-bodytext-font-family: $font-headline !default;
$video-teaser-bodytext-font-size--mobile: font-size(xl-m) !default;
$video-teaser-bodytext-font-size--desktop: font-size(xl) !default;
$video-teaser-bodytext-margin-bottom--mobile: spacing(m) !default;
$video-teaser-bodytext-margin-bottom--desktop: spacing(l) !default;
$video-teaser-btn-color-hover: color(base) !default;

.c-video-teaser {
    border-radius: $video-teaser-border-radius;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @media only screen and (min-width: 1025px) {
        flex-direction: row;
    }

    &.c-video-teaser--grid {
        flex-direction: column;

        .c-video-teaser__media {
            @media only screen and (min-width: 1025px) {
                width: 100%;
            }
        }

        .c-video-teaser__caption {
            @media only screen and (min-width: 1025px) {
                width: 100%;
            }
        }
    }

    .c-video-teaser__media {
        height: $video-teaser-media-height--mobile;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 1025px) {
            height: $video-teaser-media-height--desktop;
            width: $video-teaser-media-width--desktop;
        }

        .c-video-teaser__video {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            z-index: 0;
            border: none;
        }
    }

    .c-video-teaser__caption {
        padding: $video-teaser-caption-padding--mobile;
        width: 100%;

        @media only screen and (min-width: 1025px) {
            padding: $video-teaser-caption-padding--desktop;
            width: $video-teaser-caption-width--desktop;
            display: flex;
            align-items: flex-end;
        }

        .c-video-teaser__link {
            text-decoration: none;
            display: flex;
            flex-direction: column;

            .c-video-teaser__title {
                color: $video-teaser-title-color;
                font-family: $video-teaser-title-font-family;
                font-size: $video-teaser-title-font-size;
                letter-spacing: 0;
                margin-bottom: $video-teaser-title-margin-bottom;
            }

            .c-video-teaser__bodytext {
                color: $video-teaser-bodytext-color;
                font-family: $video-teaser-bodytext-font-family;
                font-size: $video-teaser-bodytext-font-size--mobile;
                letter-spacing: 0;
                margin-bottom: $video-teaser-bodytext-margin-bottom--mobile;

                @media only screen and (min-width: 1025px) {
                    font-size: $video-teaser-bodytext-font-size--desktop;
                    margin-bottom: $video-teaser-bodytext-margin-bottom--desktop;
                }
            }

            .c-video-teaser__btn:hover {
                color: $video-teaser-btn-color-hover;
                border: none;
            }
        }
    }

    &.dark-grey {
        background-color: #646363;
    }

    &.dark-beige {
        background-color: #AA9678;
    }

    &.medium-beige {
        background-color: #E9E4DD;

        .c-video-teaser__caption .c-video-teaser__link .c-video-teaser__title {
            color: #646363;
        }

        .c-video-teaser__caption .c-video-teaser__link .c-video-teaser__bodytext {
            color: #646363;
        }
    }

    &.light-beige {
        background-color: #F8F7F2;

        .c-video-teaser__caption .c-video-teaser__link .c-video-teaser__title {
            color: #646363;
        }

        .c-video-teaser__caption .c-video-teaser__link .c-video-teaser__bodytext {
            color: #646363;
        }
    }

    &.corporate-red {
        background-color: #E2001A;
    }

    &.light-orange {
        background-color: #F9B234;
    }

    &.sunny-yellow {
        background-color: #FFCC00;
    }

    &.dark-apple-green {
        background-color: #7AA62B;
    }

    &.medium-turquoise-green {
        background-color: #178E76;
    }

    &.dark-turquoise-green {
        background-color: #004D4D;
    }

    &.medium-red {
        background-color: #D51031;
    }

    &.dark-purple {
        background-color: #752A56;
    }

    &.blue {
        background-color: #0067AB;
    }

    &.dark-royal-blue {
        background-color: #2A2081;
    }

    &.dark-denim-blue {
        background-color: #004564;
    }

    &.desaturated-dark-red {
        background-color: #A17567;
    }
}
